var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cf-container" },
    [
      _c(
        "div",
        { staticClass: "cf-action-container" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-primary md-just-icon md-round",
              attrs: { title: "Add custom action" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleAddCarrierQuotesAction()
                },
              },
            },
            [_c("md-icon", [_vm._v("add")])],
            1
          ),
        ],
        1
      ),
      _c(
        "form-group",
        {
          staticClass: "type-filter",
          attrs: { label: "Select custom action type" },
        },
        [
          _c(
            "md-select",
            {
              on: { "md-selected": _vm.actionSelected },
              model: {
                value: _vm.selectedAction,
                callback: function ($$v) {
                  _vm.selectedAction = $$v
                },
                expression: "selectedAction",
              },
            },
            _vm._l(_vm.otherCustomActionsOptions, function (action, index) {
              return _c(
                "md-option",
                { key: index, attrs: { value: action.value } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(action.text) +
                      "\n            "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "keep-alive",
        [
          _c(_vm.actionListComponent, {
            tag: "component",
            attrs: { "custom-actions": _vm.filteredCustomActions },
            on: {
              updateClicked: _vm.updateClickedHandler,
              deleteClicked: _vm.deleteClickedHandler,
            },
          }),
        ],
        1
      ),
      _vm.filteredCustomActions.length == 0
        ? _c("div", { staticClass: "text-center" }, [
            _c("p", { staticClass: "no-result-message" }, [
              _vm._v("No results matching your search/filter could be found."),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }