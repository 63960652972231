var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c(
          "h4",
          {
            staticClass: "modal-title",
            attrs: { id: "createSmsActionModalTitle" },
          },
          [_vm._v("Update " + _vm._s(_vm.action) + " Action Settings")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body stop-form-modal" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.configModel },
          },
          [
            _c(
              "form-group",
              { attrs: { name: "alertOption", label: "Alert Option" } },
              [
                _c(
                  "md-select",
                  {
                    attrs: { id: "alert-time-frame" },
                    model: {
                      value: _vm.configModel.alertOption,
                      callback: function ($$v) {
                        _vm.$set(_vm.configModel, "alertOption", $$v)
                      },
                      expression: "configModel.alertOption",
                    },
                  },
                  _vm._l(_vm.alertTimeFrameOptions, function (item) {
                    return _c(
                      "md-option",
                      { key: item.text, attrs: { value: item.value } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.text) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm.configModel.alertOption === "Specified"
              ? _c(
                  "form-group",
                  {
                    attrs: {
                      name: "alertWindow.startTime",
                      label: "Start Time",
                    },
                  },
                  [
                    _c("TimePicker", {
                      attrs: {
                        time: _vm.configModel.alertWindow.startTime,
                        "all-time-options": false,
                        "clear-none": true,
                      },
                      on: { selectedTime: _vm.getStartTime },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.configModel.alertOption === "Specified"
              ? _c(
                  "form-group",
                  { attrs: { name: "alertWindow.endTime", label: "End Time" } },
                  [
                    _c("TimePicker", {
                      attrs: {
                        time: _vm.configModel.alertWindow.endTime,
                        "all-time-options": false,
                        "clear-none": true,
                      },
                      on: { selectedTime: _vm.getEndTime },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            attrs: { id: "btnUpdateSettings" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.updateSettings($event)
              },
            },
          },
          [_vm._v("\n            Update\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }