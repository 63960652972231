<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 id="createSmsActionModalTitle" class="modal-title">Update {{ action }} Action Settings</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body stop-form-modal">
            <form-wrapper :validator="$v.configModel" class="form-wrapper">
                <form-group name="alertOption" label="Alert Option">
                    <md-select id="alert-time-frame" v-model="configModel.alertOption">
                        <md-option v-for="item in alertTimeFrameOptions" :key="item.text" :value="item.value">
                            {{ item.text }}
                        </md-option>
                    </md-select>
                </form-group>

                <form-group
                    v-if="configModel.alertOption === 'Specified'"
                    name="alertWindow.startTime"
                    label="Start Time"
                >
                    <TimePicker
                        :time="configModel.alertWindow.startTime"
                        :all-time-options="false"
                        :clear-none="true"
                        @selectedTime="getStartTime"
                    />
                </form-group>
                <form-group v-if="configModel.alertOption === 'Specified'" name="alertWindow.endTime" label="End Time">
                    <TimePicker
                        :time="configModel.alertWindow.endTime"
                        :all-time-options="false"
                        :clear-none="true"
                        @selectedTime="getEndTime"
                    />
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button id="btnUpdateSettings" class="dialog-button md-primary" @click.prevent="updateSettings">
                Update
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { TEAM_CUSTOM_ACTION_ALERT_TIME_FRAME_OPTIONS } from '@/utils/CustomActionConstants';
import { handleRequests, showErrorMessage } from '@/helpers';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { TimePicker } from '@/components';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'ActionLevelSettings',
    mixins: [GeneralMixin],
    props: {
        action: { type: String, required: true },
        resolve: { type: Function, default: () => {} }
    },
    components: { TimePicker },
    data() {
        return {
            configModel: {
                alertOption: 'Anytime',
                alertWindow: {
                    startTime: null,
                    endTime: null
                }
            },
            alertTimeFrameOptions: TEAM_CUSTOM_ACTION_ALERT_TIME_FRAME_OPTIONS
        };
    },
    validations() {
        return {
            configModel: {
                alertOption: { required },
                alertWindow: {
                    startTime: {
                        required: requiredIf(() => {
                            return this.configModel.alertOption === 'Specified';
                        })
                    },
                    endTime: {
                        required: requiredIf(() => {
                            return this.configModel.alertOption === 'Specified';
                        })
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        }),
        duration() {
            return `from ${this.configModel.alertWindow.startTime} to ${this.configModel.alertWindow.endTime}`;
        }
    },
    watch: {
        'configModel.alertOption': function(newVal, oldVal) {
            if (!newVal || newVal === 'Anytime') {
                this.configModel.alertWindow = {
                    startTime: null,
                    endTime: null
                };
            }
        }
    },
    methods: {
        ...mapMutations('user', ['UPDATE_USER_CUSTOM_ACTION_CONFIGURATION']),
        async loadCustomActionConfiguration() {
            if (!this.user.customActionConfiguration) 
                return;

            let currentConfig = null;

            if (this.action === 'Email') {
                currentConfig = this.user.customActionConfiguration.emailSettings;
            } else if (this.action === 'Sms') {
                currentConfig = this.user.customActionConfiguration.smsSettings;
            } else if (this.action === 'Webhook') {
                currentConfig = this.user.customActionConfiguration.webhookSettings;
            }

            if (currentConfig) {
                this.configModel = {
                    ...this.configModel,
                    ...currentConfig
                };
            }
        },
        async updateSettings() {
            this.$v.configModel.$touch();
            if (this.$v.configModel.$invalid) 
                return;

            this.$_handleLoaderState(true, 'UPDATING SMS ACTION SETTINGS...');

            try {
                const api = `/api/teams/custom-actions/action-settings/${this.action}`;
                const payload = {
                    method: 'put',
                    data: this.configModel
                };

                await handleRequests(api, payload);

                this.updateUserStore();

                this.$notify({
                    message: `Successfully updated ${this.action} Action Settings.`,
                    type: 'success'
                });

                this.resolve({ result: 'ok' });
            } catch (e) {
                const message = `Could not update ${this.action} Action Settings.`;
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        async updateUserStore() {
            let updatedConfig = { ...this.user.customActionConfiguration };
            if (this.action === 'Email') {
                updatedConfig = {
                    ...updatedConfig,
                    emailSettings: { ...this.configModel }
                };
            } else if (this.action === 'Sms') {
                updatedConfig = {
                    ...updatedConfig,
                    smsSettings: { ...this.configModel }
                };
            } else if (this.action === 'Webhook') {
                updatedConfig = {
                    ...updatedConfig,
                    webhookSettings: { ...this.configModel }
                };
            }

            this.UPDATE_USER_CUSTOM_ACTION_CONFIGURATION({ config: updatedConfig });
        },
        getStartTime(selectedTime) {
            if (selectedTime) {
                const { appointmentTime } = selectedTime;
                this.configModel.alertWindow.startTime = appointmentTime;
            } else {
                this.configModel.alertWindow.startTime = null;
            }
        },
        getEndTime(selectedTime) {
            if (selectedTime) {
                const { appointmentTime } = selectedTime;
                this.configModel.alertWindow.endTime = appointmentTime;
            } else {
                this.configModel.alertWindow.endTime = null;
            }
        }
    },
    async mounted() {
        await this.loadCustomActionConfiguration();
    }
};
</script>

<style scoped lang="scss">
.modal-container {
    max-width: 600px;
}
</style>
