<template>
    <div class="modal-container" :class="{ 'wide-container': customActionModel.action === 'PlaceCarrierBooking' }">
        <div class="modal-header">
            <h4 id="createEmailActionModalTitle" class="modal-title">{{ isUpdate ? 'Update' : 'Create' }} Action</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.customActionModel" class="form-wrapper">
                <form-group name="action" label="Action Type">
                    <md-select id="action-type-select" v-model="customActionModel.action" disabled>
                        <md-option v-for="item in actionTypeOptions" :key="item.value" :value="item.value">
                            {{ item.text }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group name="eventTrigger" label="Event Trigger">
                    <md-select id="eventTrigger-select" v-model="customActionModel.eventTrigger" :disabled="isUpdate">
                        <md-option v-for="item in eventTriggers" :key="item.displayText" :value="item.eventTrigger">
                            {{ item.displayText }}
                        </md-option>
                    </md-select>
                </form-group>
                <keep-alive>
                    <component
                        :is="actionComponent"
                        :validator="$v.customActionModel"
                        :custom-action-model="customActionModel"
                        :is-update="isUpdate"
                    ></component>
                </keep-alive>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button id="btnUpdateCustomAction" class="dialog-button md-primary" @click.prevent="validate">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { TEAM_CUSTOM_ACTION_TYPE_OPTIONS } from '@/utils/constants';
import { showErrorMessage } from '@/helpers';
import { required, requiredIf, helpers } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapActions } from 'vuex';
import moment from 'moment';

const GetQuotesFromCarriers = () => import('./GetQuotesFromCarriers/GetQuotesFromCarriers');
const PlaceCarrierBooking = () => import('./PlaceCarrierBooking/PlaceCarrierBooking');

const numberIf = helpers.withParams({ type: 'numberIf' }, (value, siblings) => {
    return !helpers.req(value) || siblings.dataType !== 'Number' || !Number.isNaN(Number(value));
});

const dateIf = helpers.withParams({ type: 'dateIf' }, (value, siblings) => {
    return !helpers.req(value) || siblings.dataType !== 'Date' || new Date(value) > 0;
});

const minValueIf = helpers.withParams({ type: 'minValueIf' }, (value, siblings) => {
    let result = !helpers.req(value) || (siblings.dataType !== 'Date' && siblings.dataType !== 'Number');

    if (value) {
        if (siblings.dataType === 'Date') {
            result = result || moment(value) >= moment(siblings.value);
        } else if (siblings.dataType === 'Number') {
            result = result || +value >= +siblings.value;
        }
    }
    return result;
});

export default {
    name: 'CreateCustomAction',
    mixins: [GeneralMixin],
    props: {
        customActionModel: { type: Object, default: () => null },
        isUpdate: { type: Boolean, default: false },
        resolve: { type: Function, default: () => {} }
    },
    data() {
        return {
            actionTypeOptions: TEAM_CUSTOM_ACTION_TYPE_OPTIONS
        };
    },
    validations() {
        if (this.customActionModel.action === 'GetQuotesFromCarriers') {
            return {
                customActionModel: {
                    action: { required },
                    eventTrigger: { required },
                    settings: {
                        requestQuote: { required }
                    }
                }
            };
        }

        if (this.customActionModel.action === 'PlaceCarrierBooking') {
            return {
                customActionModel: {
                    action: { required },
                    eventTrigger: { required },
                    settings: {
                        preferredQuote: { required },
                        conditions: {
                            $each: {
                                name: { required },
                                assignTo: { required },
                                assignToOption: { required },
                                subConditions: {
                                    $each: {
                                        dataType: { required },
                                        variable: { required },
                                        operator: { required },
                                        value: {
                                            required,
                                            isNumber: numberIf,
                                            isDate: dateIf
                                        },
                                        value2: {
                                            required: requiredIf((o) => {
                                                return o.operator === 'Between';
                                            }),
                                            number: numberIf,
                                            date: dateIf,
                                            isHigherThanFirstValue: minValueIf
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            };
        }

        return {
            customActionModel: {}
        };
    },
    computed: {
        eventTriggers() {
            const action = this.actionTypeOptions.find((x) => x.value === this.customActionModel.action);
            if (action && action.eventTriggers) 
                return [...action.eventTriggers];
            return [];
        },
        actionComponent() {
            if (this.customActionModel) {
                if (this.customActionModel.action === 'GetQuotesFromCarriers') 
                    return GetQuotesFromCarriers;

                if (this.customActionModel.action === 'PlaceCarrierBooking') 
                    return PlaceCarrierBooking;
            }

            return null;
        }
    },
    methods: {
        ...mapActions('custom-actions', ['ADD_EVENT_TRIGGER', 'UPDATE_EVENT_TRIGGER']),
        async validate() {
            this.$v.customActionModel.$touch();

            if (this.isUpdate) {
                await this.update();
            } else {
                await this.create();
            }
        },
        async create() {
            this.$v.customActionModel.$touch();
            if (this.$v.customActionModel.$invalid) 
                return;

            this.$_handleLoaderState(true, 'CREATING EVENT TRIGGER...');

            try {
                await this.ADD_EVENT_TRIGGER({ data: this.customActionModel });

                this.$notify({
                    message: 'Successfully created an event trigger.',
                    type: 'success'
                });

                this.resolve({ result: 'ok' });
            } catch (e) {
                const message = 'Could not create event trigger.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        async update() {
            this.$v.customActionModel.$touch();
            if (this.$v.customActionModel.$invalid) 
                return;

            this.$_handleLoaderState(true, 'UPDATING EVENT TRIGGER...');

            try {
                await this.UPDATE_EVENT_TRIGGER({ data: this.customActionModel });

                this.$notify({
                    message: 'Successfully updated an event trigger.',
                    type: 'success'
                });

                this.resolve({ result: 'ok' });
            } catch (e) {
                const message = 'Could not update event trigger.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        }
    },
    mounted() {
        if (!this.isUpdate && this.eventTriggers && this.eventTriggers.length === 1) {
            this.customActionModel.eventTrigger = this.eventTriggers[0].eventTrigger;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-checkbox-container {
    top: 10px;
}

::v-deep .md-checkbox-label {
    opacity: 1 !important;
    color: black !important;
}

::v-deep div.md-field.no-underline:after {
    height: 0px;
}

::v-deep .md-checkbox {
    margin-top: 0px;
}

.wide-container {
    max-width: 1080px !important;
    width: 100%;
}

::v-deep .modal-body {
    max-height: 600px;
    overflow: scroll;
}
</style>
